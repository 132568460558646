<template>
  <label
    class="app-switch"
    :class="{ 'is-checked': !!value }"
    @click.prevent="handleInput">
    <input
      type="checkbox"
      :checked="isChecked"
      @input="handleInput" />
    <div class="app-switch__slider">
      <BaseSpinner
        v-if="pending"
        size="100%"
        theme="white"
        border-width="1px" />
    </div>
  </label>
</template>

<script>
export default {
  name: "AppSwitch",
  props: {
    value: {
      type: Boolean,
    },
    pending: {
      type: Boolean,
    },
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleInput() {
      if (this.pending) return;
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style lang="less" scoped>
@switch-height: 24px;
@switch-width: @switch-height*2;
@switch-slider-offset: 3px;
@slider-size: @switch-height - 2 * @switch-slider-offset;
@slider-border-width: @input-border-width;
@switch-color: @input-border-color;
@switch-active-color: @input-focus-border-color;
@switch-inactive-color: @disabled-color;
.app-switch {
  width: @switch-width;
  background-color: @input-bg-color;
  border-radius: @switch-height;
  border: @slider-border-width solid @switch-inactive-color;
  height: @switch-height;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s, transform 0.3s;

  // &:active {
  //   transform: rotateZ(25deg);
  // }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &__slider {
    position: absolute;
    width: @slider-size;
    height: @slider-size;
    background-color: @switch-inactive-color;
    display: block;
    border-radius: 50%;
    top: 50%;
    margin-top: -(@slider-size) * 0.5;
    left: @switch-slider-offset - @slider-border-width;
    transition: transform 0.3s cubic-bezier(0, 0, 0.31, 1.08), background 0.3s;
  }

  &.is-checked {
    border-color: @switch-active-color;
  }

  // &.is-checked:active {
  //   transform: rotateZ(-25deg);
  // }

  input:checked + &__slider {
    background-color: @switch-active-color;
    transform: translateX(
      @switch-width - @slider-size - (2 * @switch-slider-offset)
    );
  }
}
</style>
