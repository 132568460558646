<template>
  <div class="notice-settings">
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @close="$emit('close')">
      <h1 class="text-size-h2 text-bold mb-m">Настройки уведомлений</h1>
      <p class="text-bold">Каналы связи</p>
      <p class="mb-m">
        Выберите, где вам удобнее получать уведомления. Можно выбрать несколько
        каналов и&nbsp;подключить к&nbsp;ним разные уведомления!
      </p>
      <!-- нотификации не поддерживаются браузером -->
      <p
        v-if="!isSupportNotifications"
        class="mb-m">
        Ваш браузер не&nbsp;поддерживает Notification API, по этому мы не сможем
        оповестить вас о новых уведомлениях в личном кабинете, но&nbsp;список
        уведомлений вы&nbsp;сможете увидеть на&nbsp;этой странице.
      </p>
      <!-- нотификации не разрешены -->
      <p
        v-else-if="!isGrantedNotifications"
        class="mb-m">
        Для того чтобы получать уведомления из Личного кабинета НТО,
        <a
          class="link link--pseudo"
          @click.prevent="handlePermission"
          >разрешите эту функцию</a
        >
        в своем браузере
      </p>
      <BaseLoadingBox :pending="pending">
        <div class="row mb-l">
          <div class="col-md-8 col-sm-6 col-xs-8">
            <BaseSelect
              v-model="selectedChannel"
              :searchable="false"
              :options="channelOptions"
              placeholder="Выбрать канал"
              :allow-empty="false"
              label="text"></BaseSelect>
          </div>
        </div>
        <p class="text-bold mb-s">
          Какие уведомления вы хотите получать через этот канал?
        </p>
        <div class="mb-l">
          <div
            v-for="item in typesList"
            :key="item.type"
            class="mb-m notice-settings__option">
            <AppSwitch
              class="notice-settings__option-switch"
              :value="currentSettings.includes(item.type)"
              @input="handleChange(item.type)" />
            <div class="notice-settings__option-text">
              <div>{{ item.text }}</div>
              <div
                v-if="item.description"
                class="text-size-xs">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </BaseLoadingBox>
      <div
        v-if="error"
        class="mt-m color-error">
        <p>Не удалось получить(сохранить) настройки уведомлений.</p>
        <p>{{ error }}</p>
      </div>
      <div class="mt-m">
        <BaseButton
          id="notice_settings_save"
          :disabled="pending || modifiedChannels.length === 0"
          @click.prevent="handleSubmit"
          >Сохранить настройки</BaseButton
        >&emsp;
        <BaseButton
          id="notice_settings_abort"
          theme="primary-border"
          @click="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import AppSwitch from "@/components/AppSwitch";
import { notificationsSupported } from "@/services/notifications";

const NOTIFICATION_SUPPORTED = notificationsSupported;

import {
  MSG_TYPE_ONTI,
  MSG_TYPE_MENTOR,
  MSG_TYPE_EVENT,
  MSG_TYPE_ACHIEVEMENT,
  MSG_TYPE_ORG,
  CHANNEL_WS,
  CHANNEL_EMAIL,
  CHANNEL_TELEGRAM,
} from "@/constants/notifications";
// Типы сообщений выбранные по умолчанию (Все)
const DEFAULT_SELECTED_TYPES = [
  MSG_TYPE_ONTI,
  MSG_TYPE_MENTOR,
  MSG_TYPE_EVENT,
  MSG_TYPE_ACHIEVEMENT,
  MSG_TYPE_ORG,
];
const CHANNELS_OPTIONS = [
  {
    type: CHANNEL_WS,
    text: "Личный кабинет НТО",
  },
  {
    type: CHANNEL_EMAIL,
    text: "Эл. почта",
  },
  {
    type: CHANNEL_TELEGRAM,
    text: "Телеграм",
  },
];

const MSG_TYPES_LIST = [
  {
    type: MSG_TYPE_MENTOR,
    text: "Уведомления от наставников",
    description: "Сообщим, если наставник захочет с вами связаться",
  },
  {
    type: MSG_TYPE_ONTI,
    text: "Уведомления о событиях НТО",
    description: "Напомним о старте этапов, попыток и об открытии заданий",
  },
  {
    type: MSG_TYPE_EVENT,
    text: "Уведомления о мероприятиях и командах",
    description: "Все, что связано с объединением участников в команды",
  },
  {
    type: MSG_TYPE_ORG,
    text: "Уведомления организационного характера",
    description: "Попросим предоставить или проверить информацию",
  },
  {
    type: MSG_TYPE_ACHIEVEMENT,
    text: "Уведомления о достижениях",
    description: "Напишем, когда у вас появится новое достижение",
  },
];
export default {
  name: "NotificationSettings",
  components: {
    AppSwitch,
  },
  data() {
    return {
      name: "settings-modal",
      pending: false,
      error: "",
      selectedChannel: CHANNELS_OPTIONS[0],
      granted: false,
      settings: {
        [CHANNEL_WS]: [...DEFAULT_SELECTED_TYPES],
        [CHANNEL_EMAIL]: [...DEFAULT_SELECTED_TYPES],
        [CHANNEL_TELEGRAM]: [...DEFAULT_SELECTED_TYPES],
      },
      modifiedChannels: [],
    };
  },
  computed: {
    channelOptions() {
      return CHANNELS_OPTIONS;
    },
    notificationSettings() {
      return this.$store.state.notifications.settings;
    },
    isSupportNotifications() {
      return NOTIFICATION_SUPPORTED;
    },
    isGrantedNotifications() {
      return (
        (NOTIFICATION_SUPPORTED && Notification.permission === "granted") ||
        this.granted
      );
    },
    currentSettings() {
      const { settings, selectedChannel } = this;
      if (!selectedChannel) return [];
      return settings[selectedChannel.type];
    },
    typesList() {
      return MSG_TYPES_LIST;
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    handlePermission() {
      Notification.requestPermission()
        .then((result) => {
          if (result === "granted") {
            this.granted = true;
          }

          if (result === "denied") {
            this.showErrorModal({
              title: "Уведомления заблокированы",
              content:
                "Похоже в вашем браузере заблокированы уведомления. Разрешите их для нашего сайта",
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleChange(type) {
      const { selectedChannel, settings, modifiedChannels } = this;
      const channel = selectedChannel.type;
      const idx = settings[channel].indexOf(type);
      if (idx >= 0) {
        settings[channel].splice(idx, 1);
      } else {
        settings[channel].push(type);
      }
      // Запоминаем что настройки этого канала были изменены
      if (modifiedChannels.indexOf(channel) === -1) {
        modifiedChannels.push(channel);
      }
    },
    async getSettings() {
      this.pending = true;
      try {
        await this.$store.dispatch("notifications/getSettings");
        this.mapsettingToState();
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    /**
     * Сохраняем все измененные настройки
     */
    async handleSubmit() {
      const { pending, settings, modifiedChannels } = this;
      if (pending) return;
      this.pending = true;
      this.error = "";
      const requests = Object.entries(settings).reduce(
        (acc, [destination, types]) => {
          if (modifiedChannels.includes(destination)) {
            acc.push(
              this.$store.dispatch("notifications/saveSettings", {
                destination,
                types,
              })
            );
          }
          return acc;
        },
        []
      );
      if (requests.length) {
        try {
          await Promise.all(requests);
          // сбрасываем стейт
          this.modifiedChannels = [];
          // Синхронизируем стейт с данными из бека
          this.mapsettingToState();
        } catch (error) {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
    mapsettingToState() {
      const { notificationSettings, settings } = this;
      if (notificationSettings) {
        notificationSettings.forEach((setting) => {
          if (settings[setting.destination]) {
            settings[setting.destination] = setting.types || [
              ...DEFAULT_SELECTED_TYPES,
            ];
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.notice-settings {
  &__option {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    &-switch {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
    }

    &-text {
      flex-grow: 1;
      min-width: 1px;
    }
  }
}
</style>
