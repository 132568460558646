<template>
  <div>
    <div class="row flex-middle-md mb-m">
      <div class="col-md-6 col-sm-6 col-xs-12">
        <h1 class="text-size-h1 text-bold">{{ $metaInfo.title }}</h1>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 settings-link">
        <a
          href="#"
          class="settings-link__inner"
          @click.prevent="handleOpenSettings">
          <BaseIcon
            glyph="equalizer"
            class="color-link" />&nbsp;<span class="link link--pseudo"
            >Настройки&nbsp;уведомлений</span
          >
        </a>
      </div>
    </div>
    <BaseSpinner
      v-if="pending"
      brand />
    <div
      v-if="error"
      class="mb-m">
      <p class="mb-xs color-error">
        <BaseIcon glyph="warn" /> Не удалось получить список сообщений
      </p>
      <p class="text-size-xs">({{ error }})</p>
    </div>
    <div v-if="!list.length && !error && !pending">
      <div>Новых сообщений нет</div>
    </div>
    <div v-else-if="list.length">
      <transition-group
        name="notice-list"
        tag="div"
        appear
        mode="out-in">
        <div
          v-for="item in list"
          :key="item.id"
          class="mb-s notice-list__item">
          <NotificationCard
            :id="item.id"
            :type="item.type"
            :status="item.status"
            :date="item.send_at"
            :is-new="item.new"
            class="shadow-default"
            @setStatus="handleSetStatus"
            @addToList="handleAddToList">
            <template #title>
              {{ item.subject || `Уведомление #${item.id}` }}
            </template>
            <p>{{ item.text }}</p>
            <div
              v-if="item.link && item.isValidLink"
              class="mt-m">
              <BaseButton
                tag="a"
                class="list-btn"
                theme="primary-border"
                :href="item.link"
                @click.prevent="handleOpenLink(item.link)"
                >{{ item.actionText }}</BaseButton
              >
            </div>
          </NotificationCard>
        </div>
      </transition-group>

      <div
        v-if="showLoadMore"
        class="mt-m text-center">
        <a
          class="link link--pseudo"
          href="#"
          :disabled="pending"
          @click.prevent="loadMore"
          >Показать еще</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import findIndex from "lodash/findIndex";
import {
  MSG_STATUS_DELETED,
  MSG_STATUS_DELIVERED,
  MSG_STATUS_SENT,
  MSG_STATUS_READ,
} from "@/constants/notifications";
import { talentRequest } from "@/services/api";
import NotificationCard from "@/components/notifications/Card.vue";
import { serializeParamsWithRepeat } from "@/utils";
import NotificationSettings from "@/modals/NotificationSettings";
export default {
  name: "PageNotice",
  components: {
    NotificationCard,
  },
  metaInfo() {
    return {
      title: "Уведомления",
    };
  },
  data() {
    return {
      messages: [],
      pending: false,
      page: 1,
      error: "",
      total: 0,
    };
  },
  computed: {
    storeMessages() {
      return this.$store.state.notifications.messages;
    },
    list() {
      const { messages, storeMessages } = this;
      const ids = storeMessages.map((n) => n.id);
      const result = messages
        .filter((n) => !ids.includes(n.id))
        .sort((a, b) => {
          return b.id - a.id;
        });
      return [...storeMessages, ...result].map((n) => {
        return {
          ...n,
          actionText: "Открыть ссылку",
          new: n.status === MSG_STATUS_DELIVERED,
          // Вставляют всякую хуйню в поле ссылка
          isValidLink: n.link?.startsWith("http"),
        };
      });
    },
    showLoadMore() {
      const { messages, total } = this;
      return total > 0 && total > messages.length;
    },
  },
  async created() {
    if (!this.messages.length || this.storeMessages.length) {
      await this.getList();
    }
  },
  methods: {
    async getList() {
      const { page } = this;
      this.pending = true;
      this.error = "";
      try {
        const payload = {
          page,
          status: [MSG_STATUS_SENT, MSG_STATUS_DELIVERED, MSG_STATUS_READ],
        };
        const qs = serializeParamsWithRepeat(payload);
        const { data } = await talentRequest({
          url: `/notifications/?${qs}`,
        });
        const msgs = Array.isArray(data.items) ? data.items : [];

        if (page === 1) {
          this.messages = msgs;
        } else {
          this.messages = [...this.messages, ...msgs];
        }
        this.total = data.total;
      } catch (error) {
        console.log("error", error);
        this.error = error.message;
      }
      this.pending = false;
    },
    handleSetStatus(payload) {
      if ([MSG_STATUS_DELETED, MSG_STATUS_DELIVERED].includes(payload.status)) {
        // удаляем из стека сокет сообщений
        this.$store.commit("notifications/DELETE_MESSAGE", payload.id);
      }
      const msgIdx = findIndex(this.messages, (n) => n.id === payload.id);

      // если сообщение удалили, то удаляем из списка
      if (payload.status === MSG_STATUS_DELETED) {
        if (msgIdx >= 0) {
          this.messages.splice(msgIdx, 1);
          this.total -= 1;
        }
      } else {
        if (msgIdx >= 0) {
          this.messages[msgIdx].status = payload.status;
        }
      }
      this.$store.dispatch("notifications/setMessageStatus", payload);
    },
    handleOpenLink(url) {
      if (url.startsWith(window.location.origin)) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    },
    loadMore() {
      const { pending } = this;
      if (pending) return;
      this.page += 1;
      this.getList();
    },
    handleAddToList(id) {
      const msg = this.storeMessages.find((n) => n.id === id);
      const isExitstInList = this.messages.find((n) => n.id === id);
      const newStatus = MSG_STATUS_READ;
      this.$store.dispatch("notifications/setMessageStatus", {
        id,
        status: newStatus,
      });

      if (msg && !isExitstInList) {
        // Добавляем в список
        this.messages.push({
          ...msg,
          status: newStatus,
        });
        // удаляем из стека сокет сообщений
        this.$store.commit("notifications/DELETE_MESSAGE", id);
        return;
      }
      if (msg && isExitstInList) {
        isExitstInList.status = newStatus;
        this.$store.commit("notifications/DELETE_MESSAGE", id);
        return;
      }
      if (!msg && isExitstInList) {
        isExitstInList.status = newStatus;
      }
    },
    handleOpenSettings() {
      this.$modal.show(
        NotificationSettings,
        {},
        {
          adaptive: true,
          height: "auto",
          scrollable: true,
          minHeight: 200,
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.list-btn {
  min-width: 145px;
}
.settings-link {
  text-align: right;

  &__inner {
    display: inline-block;
    text-decoration: none;
  }

  .media-max-xs({
    text-align: left;
  });
}

.notice-list-enter-active,
.notice-list-leave-active {
  transition: opacity 0.3s, transform 0.6s;
}
.notice-list-enter, .notice-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
